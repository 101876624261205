import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import GlobalStyles from "@mui/material/GlobalStyles";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { CommonWords } from "./common-words";
import { shuffleArray } from "./utils";

const GameTime = 60;

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

function App() {
  const [words, setWords] = useState([""]);
  const [countDown, setCountDown] = useState(GameTime);
  const [currentInput, setCurrentInput] = useState("");
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [correct, setCorrect] = useState(0);
  const [incorrect, setIncorrect] = useState(0);
  const [status, setStatus] = useState("waiting");
  const textInput = useRef(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [keystrokes, setKeystrokes] = useState(0);
  const [theme, setTheme] = useState(lightTheme);

  useEffect(() => {
    if (localStorage.getItem("basicTypingTheme") === "dark") {
      setTheme(darkTheme);
    }
    setWords(generateWordsForTest());
    highlightCurrentWord(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status === "started") {
      checkIncorrectCharacters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInput]);

  function generateWordsForTest() {
    return shuffleArray(CommonWords).slice(0, 300);
  }

  function newGame() {
    if (status === "completed") {
      setWords(generateWordsForTest());
      setStatus("waiting");
      setCurrentWordIndex(0);
      setCorrect(0);
      setIncorrect(0);
      setCountDown(GameTime);
      highlightCurrentWord(true);
      setKeystrokes(0);
    }
  }

  function startGame() {
    if (status !== "started") {
      setStatus("started");
      let interval = setInterval(() => {
        setCountDown((prevCountdown) => {
          if (prevCountdown === 0) {
            clearInterval(interval);
            setStatus("completed");
            setCurrentInput("");
            return 0;
          } else {
            return prevCountdown - 1;
          }
        });
      }, 1000);
    }
  }

  function handleOnChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setCurrentInput(event.target.value);
  }

  interface HandleKeyDownProps {
    key: string;
  }

  function handleKeyDown({ key }: HandleKeyDownProps) {
    if (status !== "started") {
      startGame();
    }
    if (status === "started") {
      setKeystrokes(keystrokes + 1);
    }
    if (key === " ") {
      checkMatch();
      setCurrentInput("");
      setCurrentWordIndex(currentWordIndex + 1);
      highlightCurrentWord();
    }
  }

  function checkMatch() {
    const wordToCompare = words[currentWordIndex];
    const doesItMatch = wordToCompare.trim() === currentInput.trim();
    if (doesItMatch) {
      setCorrect(correct + 1);
      setWordColor("correct");
    } else {
      setIncorrect(incorrect + 1);
      setWordColor("incorrect");
    }
  }

  function highlightCurrentWord(newGame?: boolean) {
    if (newGame) {
      let interval = setInterval(() => {
        if (document.getElementById("0")) {
          clearInterval(interval);
          document.getElementById("0")?.classList.add("current-word");
        }
      }, 100);
    } else {
      document
        .getElementById((currentWordIndex + 1).toString())
        ?.classList.add("current-word");
      document
        .getElementById((currentWordIndex + 1).toString())
        ?.scrollIntoView({ behavior: "smooth" });
      document
        .getElementById(currentWordIndex.toString())
        ?.classList.remove("current-word");
    }
  }

  function checkIncorrectCharacters() {
    const charactersToCompare = words[currentWordIndex].trim();
    const charactersMatch =
      currentInput.trim() ===
      charactersToCompare.slice(0, currentInput.trim().length);
    if (currentInput.trim().length > 0) {
      if (charactersMatch) {
        removeWordColor();
      } else if (!charactersMatch) {
        setWordColor("incorrect");
      }
    } else if (currentInput.trim().length === 0) {
      removeWordColor();
    }
  }

  function removeWordColor() {
    document
      .getElementById(currentWordIndex.toString())
      ?.classList.remove("incorrect", "correct");
  }

  function setWordColor(className: "incorrect" | "correct") {
    document
      .getElementById(currentWordIndex.toString())
      ?.classList.add(className);
  }

  function copyResultsToClipboard() {
    const results = `Words per minute: ${correct}\r\nKeystrokes per minute: ${keystrokes}\r\nCorrect words: ${correct}\r\nIncorrect words: ${incorrect}\r\nAccuracy: ${Math.round(
      (correct / (correct + incorrect)) * 100
    )}%\r\n\r\nTry your typing speed at https://basictyping.com`;
    navigator.clipboard.writeText(results);
    setSnackbarOpen(true);
  }

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  function onThemeChange() {
    const currentTheme = theme.palette.mode === "dark" ? "dark" : "light";
    setTheme(currentTheme === "dark" ? lightTheme : darkTheme);
    localStorage.setItem(
      "basicTypingTheme",
      currentTheme === "dark" ? "light" : "dark"
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ".current-word": {
            background: theme.palette.mode === "light" ? "#ddd" : "#3e3e3e",
          },
        }}
      />
      <Box display={"none"}>
        Improve your typing skills and increase your productivity with our
        easy-to-use platform.
      </Box>
      <Box justifyContent="right" alignItems="right" display="flex">
        <Container maxWidth="md">
          <Grid
            container
            alignItems="right"
            justifyContent="right"
            sx={{ paddingTop: "20px" }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={theme.palette.mode === "dark"}
                    onChange={() => onThemeChange()}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Dark Theme"
              />
            </FormGroup>
          </Grid>
        </Container>
      </Box>
      <Box
        className="App"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        display="flex"
      >
        <Container maxWidth="md">
          {status !== "completed" && (
            <Box>
              <Grid container sx={{ maxHeight: "120px", overflow: "hidden" }}>
                {words.map((word: string, i: number) => (
                  <Typography
                    variant="body1"
                    component="span"
                    key={i}
                    id={i.toString()}
                    style={{ padding: "5px", fontSize: "1.8rem" }}
                  >
                    {word}
                  </Typography>
                ))}
              </Grid>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={10}>
                  <TextField
                    variant="outlined"
                    focused
                    autoFocus
                    fullWidth
                    placeholder="Start Typing"
                    aria-labelledby="text input"
                    ref={textInput}
                    type="text"
                    onKeyDown={handleKeyDown}
                    InputProps={{ style: { fontSize: "1.8rem" } }}
                    value={currentInput}
                    onChange={(e) => handleOnChange(e)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h5">{countDown}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Tooltip title="Restart">
                    <IconButton
                      color="primary"
                      onClick={() => window.location.reload()}
                    >
                      <RestartAltIcon fontSize="large" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          )}
          {status === "completed" && (
            <Box>
              <Grid container alignItems="center" justifyContent="center">
                <Box mb={5}>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      Words per minute: {correct}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      Keystrokes per minute: {keystrokes}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      Correct Words: {correct}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      Incorrect Words: {incorrect}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      Accuracy:{" "}
                      {correct !== 0 ? (
                        <span>
                          {Math.round((correct / (correct + incorrect)) * 100)}%
                        </span>
                      ) : (
                        "0%"
                      )}
                    </Typography>
                  </Grid>
                </Box>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button variant="contained" onClick={() => newGame()}>
                      New Game
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => copyResultsToClipboard()}
                    >
                      Share
                    </Button>
                    <Button
                      variant="contained"
                      href="https://www.paypal.com/donate/?hosted_button_id=ACDD3KVAATZ3Q"
                      target="_blank"
                    >
                      Donate
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          )}
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            action={<Button onClick={handleSnackbarClose}>Close</Button>}
            message="Copied results to clipboard!"
          />
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
